import React, {ReactElement} from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default function IndexPage(): ReactElement {
  const latestBookUrl = 'https://www.amazon.com/dp/055338371X/';
  const data = useStaticQuery(graphql`
    query {
      latestBook: file(relativePath: {eq: "books/emotional-intelligence.jpg"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 150)
        }
      }
      allMarkdownRemark(
        sort: {fields: [frontmatter___path], order: DESC}
        filter: {frontmatter: {draft: {ne: true}}}
        limit: 5
      ) {
        edges {
          node {
            frontmatter {
              title
              path
              updated
            }
          }
        }
      }
    }
  `);

  const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
  return (
    <Layout>
      <SEO title="Build Up" />

      <div className="row">
        <div className="col">
          <div className="p-5 mb-4 bg-light rounded-3">
            <h1 className="display-4">Build Up</h1>
            <p className="lead">Resources for building up, and latest blog posts.</p>
            <hr className="my-4" />
            <Link className="btn btn-success btn-lg me-3 text-light" to="/blog">
              Blog
            </Link>
            <Link className="btn btn-success btn-lg text-light" to="/library">
              Library
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="card-header">Latest Blog Posts</div>
            <div className="card-body">
              {data.allMarkdownRemark.edges.map(({node}) => (
                <div key={node.frontmatter.path}>
                  <Link to={node.frontmatter.path}>
                    &gt; {node.frontmatter.title}
                    <strong>
                      <em>
                        {' '}
                        {new Date(node.frontmatter.updated.split('-')).toLocaleDateString(
                          'en-US',
                          dateOptions
                        )}
                      </em>
                    </strong>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-3">
            <div className="card-header">Latest Library Book</div>
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <a href={latestBookUrl} target="_blank" rel="noopener noreferrer">
                  <GatsbyImage
                    image={data.latestBook.childImageSharp.gatsbyImageData}
                    className="border rounded"
                    alt="Book image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
